<template>
  <div class="main">
    <div class="box">
      <div class="row">
        <div class="col-40">
          <p v-html="$t('career.text1')"></p>
          <p v-html="$t('career.text2')"></p>
          <p v-html="$t('career.text3')"></p>
          <p v-html="$t('career.text4')"></p>
          <p v-html="$t('career.text5')"></p>
          
          <!-- Using translation key for the button text -->
          <button @click="showModal = true" class="btn-postuler">
            {{ $t("career.btn") }}
          </button>
        </div>
        <div class="col-60">
          <a href="/Regleur_machine_CN.pdf" target="_blank" class="btn-pdf">
            Voir l'offre pour un.e régleur.euse CN
          </a>
        </div>
      </div>
    </div>

    <!-- Modal implementation with same structure as website X -->
    <Transition>
      <div v-if="showModal" class="modal">
        <div class="modal-close" @click="showModal = false"></div>
        <div class="modal-container">
          <form @submit.prevent="onSubmit">
            <template v-if="!success">
              <div class="row">
                <!-- Name -->
                <div class="form-group" :class="{ error: v$.form.name.$errors.length }">
                  <input class="form-control" :placeholder="$t('career.form.name')" type="text" v-model="v$.form.name.$model">
                  <!-- error message -->
                  <template v-if="v$.form.name.$errors.length > 0">
                    <div class="error-msg" v-if="v$.form.name.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                    <div class="error-msg" v-if="v$.form.name.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.name.$errors[0].$params.max}) }}</div>
                  </template>
                </div>
              </div>

              <div class="row">
                <!-- Email -->
                <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                  <input class="form-control" :placeholder="$t('career.form.email')" type="email" v-model="v$.form.email.$model">
                  <!-- error message -->
                  <template v-if="v$.form.email.$errors.length > 0">
                    <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                    <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">{{ $t("career.form.invalidEmail") }}</div>
                    <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.email.$errors[0].$params.max}) }}</div>
                  </template>
                </div>
              </div>

              <div class="row">
                <!-- Phone -->
                <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
                  <input class="form-control" :placeholder="$t('career.form.phone')" type="text" v-model="v$.form.phone.$model">
                  <!-- error message -->
                  <template v-if="v$.form.phone.$errors.length > 0">
                    <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.phone.$errors[0].$params.max}) }}</div>
                  </template>
                </div>
              </div>

              <div class="row">
                <!-- Job -->
                <div class="form-group" :class="{ error: v$.form.job.$errors.length }">
                  <input class="form-control" :placeholder="$t('career.form.job')" type="text" v-model="v$.form.job.$model">
                  <!-- error message -->
                  <template v-if="v$.form.job.$errors.length > 0">
                    <div class="error-msg" v-if="v$.form.job.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                    <div class="error-msg" v-if="v$.form.job.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.job.$errors[0].$params.max}) }}</div>
                  </template>
                </div>
              </div>

              <div class="row">
                <!-- Message -->
                <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                  <textarea class="form-control" :placeholder="$t('career.form.message')" v-model="v$.form.message.$model" rows="5"></textarea>
                  <!-- error message -->
                  <template v-if="v$.form.message.$errors.length > 0">
                    <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                      <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                      <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.message.$errors[0].$params.max}) }}</div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="row">
                <!-- file upload -->
                <div class="form-group">
                  <input class="form-control" :placeholder="$t('career.form.file')" v-on:change="handlePdfUpload($event)" type="file" accept="application/pdf" />
                  <div class="error-msg" v-if="errorPdf">{{ errorPdf }}</div>
                </div>
              </div>

              <div class="row">
                <!-- rgpd -->
                <div class="form-group" :class="{ error: v$.form.rgpd.$errors.length }">
                  <div class="checkbox-container">
                    <input class="form-control checkbox" type="checkbox" v-model="v$.form.rgpd.$model">
                    <label>{{ $t("career.form.rgpd") }} <router-link :to="{ name : 'DataPrivacy' }" target="_blank">{{ $t("career.form.rgpdLink") }}</router-link></label>
                  </div>
                  <!-- error message -->
                  <template v-if="v$.form.rgpd.$errors.length > 0">
                    <div class="error-msg" v-if="v$.form.rgpd.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                    <div class="error-msg" v-if="v$.form.rgpd.$errors[0].$validator == 'checked'">{{ $t("career.form.required") }}</div>
                  </template>
                </div>
              </div>

              <!-- Submit Button -->
              <div class="row-btn">
                <button :disabled="v$.form.$invalid" class="btn">{{ $t("career.form.btn") }}</button>
              </div>
            </template>

            <!-- Success -->
            <div class="notices success" v-if="success">{{ $t("career.form.success") }}</div>
            <!-- Errors -->
            <div class="notices errors" v-if="errors">{{ errors }}</div>
          </form>

          <div class="loader-container">
            <div class="loader" v-if="isLoading"></div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';
import axios from 'axios';

export default {
  name: "Career",
  data() {
    return {
      showModal: false,
      form: {
        name: "",
        email: "",
        phone: "",
        job: "",
        message: "",
        file: "",
        rgpd: false,
        site: 3 // Make sure this is the right site ID for your API
      },
      errorPdf: null,
      success: false,
      errors: null,
      isLoading: false
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required, max: maxLength(150)
        },
        email: {
          required, email, max: maxLength(150)
        },
        phone: {
          max: maxLength(20)
        },
        job: {
          max: maxLength(150), required
        },
        message: {
          required, max: maxLength(1500)
        },
        rgpd: {
          required,
          checked: value => value === true
        }
      }
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.errors = null;
      this.success = false;

      let config = {
        validateStatus: () => true
      };

      // Use axios like in the original code
      axios
        .post(`${this.$store.state.apiUrl}/public/job/2`, this.form, config)
        .then(res => {
          this.isLoading = false;
          if (res.status != 200) {
            this.errors = res.data.errors;
          } else {
            this.success = true;
            
            // Reset form after delay
            setTimeout(() => {
              this.resetForm();
            }, 5000);
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          this.errors = this.$t("career.form.error");
        });
    },
    
    handlePdfUpload(event) {
      this.errorPdf = null;
      const formData = new FormData();
      formData.append('pdf', event.target.files[0]);

      let config = {
        validateStatus: () => true,
      };
      
      this.isLoading = true;
      
      axios
        .post(`${this.$store.state.apiUrl}/public/upload-pdf`, formData, config)
        .then(res => {
          this.isLoading = false;
          if (res.status != 200) {
            this.errorPdf = res.data.message;
          } else {
            this.form.file = res.data.pdf;
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          this.errorPdf = this.$t("career.form.fileError");
        });
    },
    
    resetForm() {
      this.form = {
        name: "",
        email: "",
        phone: "",
        job: "",
        message: "",
        file: "",
        rgpd: false,
        site: 2
      };
      this.errorPdf = null;
      this.v$.$reset();
    }
  }
};
</script>

<style scoped lang="scss">
.row {
  margin-top: 40px;
  margin-bottom: 40px;
}

.col-40 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
  border-right: 2px solid $mainColor;
}

p {
  font-size: 20px;
  text-align: right;
}

/* Button styles */
.btn-pdf, .btn-postuler {
  display: inline-block;
  padding: 12px 25px;
  background-color: $mainColor;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
}

.btn-pdf:hover, .btn-postuler:hover {
  background-color: darken($mainColor, 10%);
}

/* Modal styles */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1001;
  
  &::before, &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: white;
    top: 50%;
  }
  
  &::before {
    transform: rotate(45deg);
  }
  
  &::after {
    transform: rotate(-45deg);
  }
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  width: 800px;
  max-width: 96%;
  padding: 30px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Form styles */
form {
  width: 100%;
  position: relative;
  z-index: 1;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  position: relative;
}

.form-control {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  &.checkbox {
    width: auto;
    margin-right: 10px;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.form-control:focus {
  border-color: $mainColor;
  outline: none;
}

.error-msg {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.btn {
  width: 100%;
  padding: 12px;
  background-color: $mainColor;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  &:not(:disabled):hover {
    background-color: darken($mainColor, 10%);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid $mainColor;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.notices {
  text-align: center;
  padding: 15px;
  margin: 20px 0;
  border-radius: 5px;
  
  &.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  &.errors {
    background-color: #f8d7da;
    color: #721c24;
  }
}

/* Transition effects */
.v-enter-active, .v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from, .v-leave-to {
  opacity: 0;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  p {
    font-size: 15px;
  }
}
</style>
